@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  background-color: #000814;
  font-family: 'Orbitron', sans-serif;
}

::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
::-webkit-scrollbar-track {
    background: #000814;
  }
  
  /* Handle */
:hover::-webkit-scrollbar-thumb {
    background: #FFFFFF;
  }



  @keyframes scroll {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }
  
  .animate {
    animation: scroll 15s linear infinite;
  }